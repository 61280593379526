import classNames from 'classnames';
import { useEffect, useRef } from 'react';

const SITE_KEY = process.env.TURNSTILE_SITE_KEY || '0x4AAAAAAA1aRClQo6-OwKx9';
const jsLib = 'https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback';

interface TurnstileProps {
    onToken: (token: string) => void;
    onError: (error: string) => void;
    className?: string;
}

export function Turnstile(props: TurnstileProps) {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = jsLib;
        script.defer = true;
        script.onload = () => {
            // @ts-ignore
            window.onloadTurnstileCallback = () => {
                // @ts-ignore
                window.turnstile.render(ref.current, {
                    size: 'flexible',
                    sitekey: SITE_KEY,
                    callback: function (token) {
                        props.onToken(token);
                    },
                    'error-callback': function (error) {
                        console.error('Turnstile error: ', error);
                        props.onError(error);
                    },
                });
            };
        };
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <>
            <div className={classNames(props.className)} ref={ref} style={{ width: '100%' }} />
        </>
    );
}
