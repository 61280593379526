@import '../../../style/globals.scss';

.BundleItem {
    position: relative;
    width: 100%;
    padding: 30px;
    background-size: 100% 100%;
    aspect-ratio: 4 / 5;

    &.noBackgroundImage {
        background-color: var(--token-accent-theme-1-interface-mono-interface-background);
        border-radius: 10px;
    }

    .label {
        position: absolute;
        z-index: 1;
        top: 40px;
        left: 20px;
        min-width: 170px;
    }

    .avatar {
        bottom: 60px !important;

        @media (max-width: $bp-xxs) {
            bottom: 110px !important;
        }
    }

    .purchasedOverlay {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);

        div {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            text-align: center;

            @media (max-width: $bp-xxs) {
                padding-bottom: 50px;
            }
        }

        svg {
            display: inline-block;
            width: 20%;
            margin-bottom: 10px;
        }

        p {
            font-family: var(--font-primary);
            font-size: $font-size-h6;
            text-align: center;

            @media (max-width: $bp-xxs) {
                font-size: $font-size-small;
            }
        }
    }

    .interactionContainer {
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        gap: 10px 10px;
        width: 100%;
        padding: 20px;

        & > * {
            width: 50%;
        }

        .cardDescription {
            height: auto;
            padding-right: 32px;
        }

        @media (max-width: $bp-xxs) {
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            .cardDescription {
                max-width: 170px;
            }

            button {
                width: 100%;
            }
        }
    }

    &.longTitle {
        .avatar {
            bottom: 90px !important;
        }

        @media (max-width: $bp-xxs) {
            .avatar {
                bottom: 110px !important;
            }

            .cardDescription {
                max-width: 500px;
            }
        }
    }

    .loadingScreen {
        z-index: 10;
        background-color: black;
        transition: none;
    }
}
