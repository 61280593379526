import classNames from 'classnames';
import { useEffect, useState } from 'react';

import { useTheme } from '@/theme';
import { getSupplyRunUserProfileStats } from '@/arise/api';
import { SupplyRunUserStats } from '@/models/misc';
import { convertNumberToOrdinal, localiseNumber } from '@/utils/string';
import Icon, { IconTypes } from '@/components/Icon';

import * as styles from './styles.module.scss';

interface ProfileStatsSummaryProps {
    numberOfTitles: number;
    puzzlesCompleted: number;
    userID: string;
    role?: string;
    classname?: string;
}

const ProfileStatsSummary = ({
    numberOfTitles,
    puzzlesCompleted,
    userID,
    role,
    classname,
}: ProfileStatsSummaryProps) => {
    const theme = useTheme();
    const backgroundImage = theme.customJSON?.profileBackground;

    const [supplyRunStats, setSupplyRunStats] = useState<SupplyRunUserStats>(null);
    const shouldShowSupplyRunData = theme.metadata?.hasSupplyRun && role !== 'guest' && role !== 'actor';
    const shouldReduceFontSize = supplyRunStats?.supplyPoints > 99999;

    async function getSupplyRunStats() {
        try {
            const response = await getSupplyRunUserProfileStats(userID);
            setSupplyRunStats(response);
        } catch (error) {
            console.warn('Could not get SupplyRunUserProfileStats:', error);
        }
    }

    useEffect(() => {
        if (shouldShowSupplyRunData) {
            getSupplyRunStats();
        }
    }, [shouldShowSupplyRunData]);

    return (
        <div
            className={classNames(styles.ProfileStatsSummary, classname, {
                [styles.noBackgroundImage]: !backgroundImage,
            })}
            style={backgroundImage ? { backgroundImage: `url("${backgroundImage}")` } : {}}
        >
            <div className={styles.logo} style={{ maskImage: `url("${theme.files.instance_mark}")` }}></div>
            <div className={styles.summaryItems}>
                <ul className={styles.summaryRow}>
                    <li>
                        <ProfileStatSummaryItem iconType="crown" title="Titles:" value={numberOfTitles} />
                    </li>
                    <li>
                        <ProfileStatSummaryItem iconType="challenge" title="Puzzles Done:" value={puzzlesCompleted} />
                    </li>
                </ul>
                {shouldShowSupplyRunData && supplyRunStats && (
                    <ul className={classNames(styles.summaryRow, styles.extraStats)}>
                        <li>
                            <ProfileStatSummaryItem
                                iconType="supplies"
                                title="Supplies:"
                                value={localiseNumber(supplyRunStats?.supplyPoints, 0)}
                                reduceFontSize={shouldReduceFontSize}
                            />
                        </li>
                        <li>
                            <ProfileStatSummaryItem
                                iconType="bolt"
                                title="Leaderboard:"
                                value={convertNumberToOrdinal(supplyRunStats?.position)}
                                reduceFontSize={shouldReduceFontSize}
                            />
                        </li>
                    </ul>
                )}
            </div>
        </div>
    );
};

interface ProfileStatSummaryItemProps {
    iconType: IconTypes;
    title: string;
    value: string | number;
    classname?: string;
    reduceFontSize?: boolean;
}

export const ProfileStatSummaryItem = ({
    iconType,
    title,
    value,
    classname,
    reduceFontSize,
}: ProfileStatSummaryItemProps) => {
    return (
        <div className={classNames(styles.SummaryItem, classname, { [styles.reduceFontSize]: reduceFontSize })}>
            <Icon className={styles.icon} type={iconType} />
            <div>
                <span className={styles.title}>{title}</span>
                <span className={styles.value}>{value}</span>
            </div>
        </div>
    );
};

export default ProfileStatsSummary;
