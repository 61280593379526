import React from 'react';
import Markdown from 'markdown-to-jsx';
import classNames from 'classnames';

import { safeDecodeBase64, safeDecodeURIComponent } from '@/utils/string';

import * as styles from './styles.module.scss';

const LinkRenderer = ({ href, children }: { href?: string; children: React.ReactNode }) => {
    if (!href) {
        return <span>{children}</span>;
    }

    return (
        <a href={href} target="_blank" rel="noreferrer">
            {children}
        </a>
    );
};

// Renders heading with provided level
const HeadingRenderer = (level: number) => {
    const Tag = `h${level}` as keyof JSX.IntrinsicElements;
    return ({ children }: { children: React.ReactNode }) => <Tag>{children}</Tag>;
};

/* Accepts markdown as plaintext or base64 and renders JSX. Uses a custom component to render anchors.
Comes with some default CSS, which can be stopped by setting hasDefaultStyling to false */

const MarkdownRenderer = ({
    markdownContent,
    base64MarkdownContent,
    hasDefaultStyling = true,
    textAlign = 'center',
}: {
    markdownContent?: string;
    base64MarkdownContent?: string;
    hasDefaultStyling?: boolean;
    textAlign?: 'center' | 'left';
}) => {
    if (!base64MarkdownContent && !markdownContent) {
        console.error('No content found for markdown');
        return null;
    }

    const content = markdownContent ?? safeDecodeURIComponent(safeDecodeBase64(base64MarkdownContent));
    if (!content) return null;

    return (
        <div
            className={classNames(styles.MarkdownRenderer, {
                [styles.hasDefaultStyling]: hasDefaultStyling,
                [styles.centerAlign]: textAlign === 'center',
                [styles.leftAlign]: textAlign === 'left',
            })}
        >
            <Markdown
                options={{
                    overrides: {
                        // Bump all headings down by 1, so highest heading can be h2. h1 is reserved for main website title.
                        h1: HeadingRenderer(2),
                        h2: HeadingRenderer(3),
                        h3: HeadingRenderer(4),
                        h4: HeadingRenderer(5),
                        h5: HeadingRenderer(6),
                        a: LinkRenderer,
                    },
                    disableParsingRawHTML: true,
                }}
            >
                {content}
            </Markdown>
        </div>
    );
};

export default MarkdownRenderer;
