@import '../../../style/globals.scss';

$backgroundColor: rgba(0, 0, 0, 0.8);

.BundleModal {
    position: absolute;
    z-index: 20;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: 100vh;
    background-color: $backgroundColor;
    animation: fadeInBackground 0.4s ease-out forwards;

    &.closeAnimation {
        animation: fadeOutBackground 0.4s ease-out forwards;

        .modal {
            animation: bulgeEffectOut 0.3s ease-out forwards;
        }
    }

    .modal {
        position: relative;
        top: 50px;
        width: 450px;
        max-width: calc(100% - 40px);
        margin: auto;
        background-size: 100% 100%;
        animation: bulgeEffectIn 0.3s ease-out forwards;
        max-height: calc(100vh - 100px);
        max-height: calc(100dvh - 100px); // Fix for mobile safari

        &.noBackgroundImage {
            background-color: var(--token-accent-theme-1-interface-mono-interface-midground);
            border-radius: 10px;
        }
    }

    .scrollContainer {
        @include scrollbar-styles;
        position: relative;
        overflow-y: auto;
        width: 100%;
        max-height: calc(100vh - 100px);
        max-height: calc(100dvh - 100px); // Fix for mobile safari
        padding: 20px;
    }

    .closeButton {
        position: absolute;
        z-index: 1;
        top: -15px;
        right: -15px;
    }

    .title {
        width: 100%;
        padding: 0px 20px;
        text-align: center;
    }

    .assets {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        gap: 10px;
        padding: 20px 0px;

        li {
            width: calc(50% - 5px);
        }

        .frameItem {
            img {
                width: calc(100% - 40px);
                margin-bottom: 25px;
            }
        }

        .characterItem {
            img {
                width: calc(100% - 40px);
                height: calc(100% - 40px);
                margin: 20px;
            }
        }

        .backgroundItem {
            img {
                width: calc(100% - 40px);
                height: calc(100% - 40px);
                margin: 20px 20px 30px 20px;
            }
        }

        .blankItem {
            // height: calc(100% - 6px);
            opacity: 0.5;
            pointer-events: none;

            &.noBackgroundImage {
                background-color: var(--token-accent-theme-1-interface-mono-interface-background);
                border-radius: 10px;
                height: 100%;
                width: 100%;
            }
        }
    }
    .interactionContainer {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        gap: 10px 10px;
        width: 100%;

        & > * {
            width: 50%;
        }

        @media (max-width: $bp-xxs) {
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            & > * {
                width: 100%;
            }
        }
    }

    .bottomText {
        padding-top: 15px;
        color: var(--token-accent-theme-1-text-text-default);
        font-family: var(--font-tertiary);
        font-size: $font-size-p;
        font-weight: 300;
        opacity: 0.5;
    }

    .guestOverlay {
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        min-height: 70%;
        padding: 20px 20px 80px 20px;
        text-align: center;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 50%, transparent 100%);
        pointer-events: none;

        & > * {
            pointer-events: all;
        }

        h2,
        p {
            padding-bottom: 20px;
        }
    }

    @keyframes fadeInBackground {
        0% {
            background-color: transparent;
        }
        100% {
            background-color: $backgroundColor;
        }
    }
    @keyframes fadeOutBackground {
        0% {
            background-color: $backgroundColor;
        }
        100% {
            background-color: transparent;
        }
    }

    @keyframes bulgeEffectIn {
        0% {
            transform: scale(0.8);
            opacity: 0;
        }
        50% {
            transform: scale(1.04);
            opacity: 1;
        }
        100% {
            transform: scale(1);
        }
    }
    @keyframes bulgeEffectOut {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.04);
        }
        65% {
            transform: scale(1.04);
            opacity: 1;
        }
        100% {
            transform: scale(0.8);
            opacity: 0;
        }
    }
}
