import { useNavigate, useParams } from 'react-router-dom';

import ChatMessage from '@/components/Feed/ChatMessage';
import EventSummaryMessage from '@/components/Feed/EventSummaryMessage';
import InteractionPrompt from '@/components/Feed/InteractionPrompt';
import Update from '@/components/Feed/Update';
import { FeedItem } from '@/models/feed';

import * as styles from './styles.module.scss';
import { memo } from 'react';

interface FeedItemRendererProps extends React.HTMLAttributes<HTMLDivElement> {
    item: FeedItem;
}

function FeedItemRenderer({ item }: FeedItemRendererProps) {
    function renderFeedItem() {
        switch (item.type) {
            case 'chat-message':
                return <ChatMessage message={item.data} />;
            case 'feed-update':
                return <Update {...item} />;
            case 'interaction-prompt':
                return <InteractionPrompt {...item.data} />;
            case 'event-summary':
                return <EventSummaryMessage message={item} />;
            default:
                // @ts-ignore
                console.error('Unknown feed item type:', item.type);
                return null;
        }
    }
    return <div className={styles.feedItem}>{renderFeedItem()}</div>;
}

// Memoise the FeedItemRenderer component to prevent unnecessary re-renders
export default memo(FeedItemRenderer, (prev, next) => {
    if (prev.item.id !== next.item.id) {
        return false;
    }

    if (prev.item.data.reactions && next.item.data.reactions) {
        if (prev.item.data.reactions.length !== next.item.data.reactions.length) {
            return false;
        } else if (
            prev.item.data.reactions.some((reaction, index) => {
                return reaction.count !== next.item.data.reactions[index].count;
            })
        ) {
            return false;
        }
    }
    if (
        (prev.item.data.reactions && !next.item.data.reactions) ||
        (!prev.item.data.reactions && next.item.data.reactions)
    ) {
        return false;
    }

    return true;
});
