import { useEffect, useState } from 'react';

import Input from '@/components/Forms/Input';
import { useAppDispatch, useAppSelector } from '@/state/store';
import { isValidEmail } from '@/utils/validation';
import { loadCurrentUserProfile } from '@/state/features/profile';
import { login } from '@/state/features/auth';

import { AuthButton, ExternalLoginButtons } from '../../components/LoginButton';
import { ButtonLink } from '../../components/ButtonLink';
import { AriseAuthScreenProps } from '../..';
import * as styles from '../../styles.module.scss';

export default function Login({ navigateToScreen, closeModal }: AriseAuthScreenProps) {
    const auth = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    function onSubmit() {
        if (auth.isRegistering) return;

        if (!isValidEmail(email)) {
            setErrorMessage('Please enter a valid email address');
            return;
        }

        if (!email || !password) return;
        dispatch(login([email, password]));
    }

    useEffect(() => {
        // New user has been logged in
        if (auth.user?.email === email) {
            dispatch(loadCurrentUserProfile([auth.user.id]));
            closeModal();
        }
    }, [auth.user]);

    useEffect(() => {
        if (auth.loginError) {
            setErrorMessage('Login failed');
        }
    }, [auth.loginError]);

    return (
        <div>
            <h2>Log in</h2>
            <ExternalLoginButtons />
            <div className={styles.separator}>or</div>
            <label htmlFor="arise-login-email">Email</label>
            <Input
                className={styles.input}
                id="arise-login-email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email Address"
                autoComplete="email"
            />
            <label htmlFor="arise-login-password">Password</label>
            <Input
                className={styles.input}
                id="arise-login-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                type="password"
                autoComplete="new-password"
                style={{ marginBottom: 12 }}
            />
            <ButtonLink onClick={() => navigateToScreen('forgottenPassword')} className={styles.forgotPassword}>
                Forgotten Password?
            </ButtonLink>
            <AuthButton disabled={!(email && password)} text="Log in" onClick={onSubmit} />
            <span className={styles.bottomText}>
                Don't have an account?{' '}
                <ButtonLink isHighlighted onClick={() => navigateToScreen('signupLanding')}>
                    Sign Up
                </ButtonLink>
            </span>
            <p className={styles.errorMessage}>{errorMessage}</p>
        </div>
    );
}
