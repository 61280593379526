@import '../../style/globals.scss';

.MarkdownRenderer {
    &.hasDefaultStyling {
        // h1s should be turned into h2s in component
        h1,
        h2 {
            font-family: var(--font-primary);
            font-size: $font-size-h5;
        }

        h3,
        h4,
        h5,
        h6 {
            padding-bottom: 5px;
            font-size: $font-size-h6;
        }

        p {
            // Bare in mind that a lot of elements like images get wrapped in a p tag
            padding: 5px 0px;
            font-size: $font-size-p;
        }

        ul,
        ol {
            display: inline-block;
            margin-bottom: 10px;
            margin-left: 20px;
            font-size: $font-size-p;
            text-align: left;
        }

        ul {
            li {
                list-style-type: disc;
            }
        }

        ol {
            li {
                list-style-type: auto;
            }
        }

        a {
            color: var(--token-accent-theme-1-text-text-link);

            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }
    }

    &.centerAlign {
        text-align: center;
    }

    &.leftAlign {
        text-align: left;
    }
}
