import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getAssetPackById, pollSessionStatus } from '@/utils/shop';
import { setProfileAsset } from '@/utils/profile';
import { sendEvent } from '@/utils/analytics';
import { useAppDispatch, useAppSelector } from '@/state/store';
import { openProfile, setIsPurchaseInProgress, setIsShopOpen } from '@/state/features/app';
import { AssetPack } from '@/models/shop';
import { useTheme } from '@/theme';
import { SUPPORT_EMAIL } from '@/theme/vars';
import LoadingScreen from '@/components/LoadingScreen';
import Button from '@/components/Forms/Button';
import IconButton from '@/components/IconButton';
import ProfileAssetsContainer from '@/components/ProfileDrawer/ProfileAssetsContainer';

import * as styles from './styles.module.scss';

interface PostPurchaseScreenProps {
    classname?: string;
}

const Verifying = () => (
    <div>
        <LoadingScreen show={true} hasBackground={false} classname={styles.loadingSpinner} />
        <h2>Verifying Purchase</h2>
        <p>Please wait...</p>
    </div>
);

const Failed = ({ onClose }) => {
    const emailHref = `mailto:${SUPPORT_EMAIL}?subject=Something went wrong`;
    return (
        <>
            <div>
                <h2 className="mb-4">Sorry, something went wrong.</h2>
                <p>
                    Please try again, or contact Support for assistance:
                    <br />
                    <br />
                    <a href={emailHref} onClick={() => sendEvent('shop_post_purchase_fail_support_email_click')}>
                        {SUPPORT_EMAIL}
                    </a>
                </p>
            </div>

            <Button onClick={onClose}>Close</Button>
        </>
    );
};

const SuccessNoPack = ({ onClose }) => {
    // Just in case we can't find the pack from the API, but payment was successful.
    return (
        <>
            <div>
                <h2 className="mb-4">Payment Successful!</h2>
                <p>This pack is now part of your profile collection.</p>
            </div>

            <Button onClick={onClose}>Close</Button>
        </>
    );
};

const CheckoutCancelled = ({ onClose }) => {
    // If user presses back from stripe
    return (
        <>
            <div>
                <h2 className="mb-4">Checkout Cancelled</h2>
            </div>
            <Button onClick={onClose}>Close</Button>
        </>
    );
};

const Success = ({ pack, onClose }: { pack: AssetPack; onClose: () => void }) => {
    const dispatch = useAppDispatch();
    const currentUserProfile = useAppSelector((state) => state.profile.currentUserProfile);

    const successOnApplyNow = () => {
        setProfileAsset('background', pack.assets.background.id);
        setProfileAsset('character', pack.assets.character.id);
        setProfileAsset('avatar_frame', pack.assets.avatarFrame.id);
        onClose();
        dispatch(openProfile(currentUserProfile));
        sendEvent('shop_post_purchase_success_apply_pack');
    };

    return (
        <div>
            <IconButton
                iconType="close"
                buttonStyle="round"
                ariaLabel="Close"
                className={styles.closeButton}
                onClick={onClose}
            />
            <div className={styles.scrollContainer}>
                <ProfileAssetsContainer
                    classname={styles.assetsContainer}
                    background={pack.assets.background}
                    character={pack.assets.character}
                    avatarFrame={pack.assets.avatarFrame}
                />
                <h2>You now own {pack.name}</h2>
                <p>This pack is now part of your profile collection.</p>
                <div className={styles.buttons}>
                    <Button
                        className={styles.later}
                        onClick={() => {
                            sendEvent('shop_post_purchase_success_apply_later');
                            onClose();
                        }}
                    >
                        Later
                    </Button>
                    <Button onClick={successOnApplyNow}>Apply Now</Button>
                </div>
            </div>
        </div>
    );
};

export const PostPurchaseScreen = ({ classname }: PostPurchaseScreenProps) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [uiStatus, setUiStatus] = useState<
        'hidden' | 'verifying' | 'failed' | 'success' | 'successNoPack' | 'checkoutCancelled'
    >('hidden');
    const [selectedPack, setSelectedPack] = useState<AssetPack>();

    useEffect(() => {
        const checkSessionStatus = async () => {
            const searchParams = new URLSearchParams(window.location.search);
            const isCheckout = searchParams.get('checkout');
            if (isCheckout) {
                dispatch(setIsPurchaseInProgress(true));
                const urlStatus = searchParams.get('status'); // 'confirmed' or 'cancel'
                const urlSessionId = searchParams.get('session_id');
                navigate(window.location.pathname, { replace: true }); // Clean URL

                if (urlStatus === 'success' && urlSessionId) {
                    setUiStatus('verifying');
                    try {
                        const response = await pollSessionStatus(urlSessionId);
                        if (response) {
                            if (response.status === 'confirmed') {
                                const selectedPack = await getAssetPackById(response.items[0]?.id);

                                if (selectedPack?.assets) {
                                    setSelectedPack(selectedPack);
                                    setUiStatus('success');
                                    sendEvent('shop_post_purchase_success');
                                } else {
                                    setUiStatus('successNoPack');
                                    sendEvent('shop_post_purchase_success_nopack');
                                }
                            }
                        } else {
                            console.warn('Session verification failed or timed out');
                            setUiStatus('failed');
                            sendEvent('shop_post_purchase_fail', {
                                message: 'Session verification failed or timed out',
                            });
                        }
                    } catch (error) {
                        console.error('Could not verify session status');
                        setUiStatus('failed');
                        sendEvent('shop_post_purchase_fail', {
                            message: 'Could not verify session status',
                        });
                    }
                } else if (urlStatus === 'cancel') {
                    console.warn('Checkout URL has status cancel');
                    dispatch(setIsShopOpen(true));
                    setUiStatus('checkoutCancelled');
                    sendEvent('shop_post_purchase_cancelled');
                } else {
                    console.warn('Invalid URL params:', urlStatus, urlSessionId);
                    sendEvent('shop_post_purchase_invalid_url');
                    closeScreen();
                }
            }
        };

        checkSessionStatus();
    }, []);

    const closeScreen = () => {
        dispatch(setIsPurchaseInProgress(false));
        setUiStatus('hidden');
    };

    const getContent = () => {
        switch (uiStatus) {
            case 'checkoutCancelled':
                return <CheckoutCancelled onClose={closeScreen} />;
            case 'verifying':
                return <Verifying />;
            case 'failed':
                return <Failed onClose={closeScreen} />;
            case 'success':
                return <Success onClose={closeScreen} pack={selectedPack} />;
            case 'successNoPack':
                return <SuccessNoPack onClose={closeScreen} />;
            default:
                break;
        }
    };
    if (uiStatus === 'hidden') {
        return null;
    }
    const backgroundImage = theme.customJSON?.profileBackground;
    return (
        <div className={classNames(styles.PostPurchaseScreen, classname)}>
            <div className={styles.background}></div>
            <div
                className={classNames(styles.modal, styles[uiStatus], { [styles.noBackgroundImage]: !backgroundImage })}
                style={backgroundImage ? { backgroundImage: `url("${theme.customJSON?.profileBackground}")` } : {}}
            >
                {getContent()}
            </div>
        </div>
    );
};

export default PostPurchaseScreen;
