@import '../../../style/globals.scss';

.PostPurchaseScreen {
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    a {
        text-decoration: underline;
    }

    .modal {
        position: absolute;
        top: 50%;
        left: 50%;
        display: flex;
        width: 300px;
        max-width: calc(100% - 40px);
        min-height: 300px;
        padding: 40px;
        background-size: 100% 100%;
        text-align: center;
        transform: translate(-50%, -50%);

        &.noBackgroundImage {
            background-color: var(--token-accent-theme-1-interface-mono-interface-midground);
            border-radius: 10px;
        }

        &.verifying {
            justify-content: center;
            align-items: end;
        }

        &.failed,
        &.successNoPack,
        &.checkoutCancelled {
            flex-direction: column;
            justify-content: space-between;
        }

        &.failed {
            min-height: 320px;
        }

        &.checkoutCancelled {
            min-height: 200px;
        }

        &.success {
            display: block;
            width: 450px;
            padding: 0px;

            .scrollContainer {
                @include scrollbar-styles;
                position: relative;
                overflow-x: hidden;
                overflow-y: auto;
                width: 100%;
                max-height: calc(100vh - 50px);
                padding: 40px;
            }

            h2 {
                margin: 10px 0px;
            }

            p {
                margin-bottom: 15px;
                font-size: $font-size-small;
            }

            .assetsContainer {
                aspect-ratio: 10/12;
            }

            .closeButton {
                position: absolute;
                z-index: 1;
                top: -15px;
                right: -15px;
            }

            .buttons {
                display: flex;
                flex-direction: row;
                justify-content: left;
                align-items: center;
                gap: 10px 10px;
                width: 100%;

                .later {
                    border: 1px solid var(--token-accent-theme-1-button-button-default);
                    background-color: transparent;

                    &:hover,
                    &:focus {
                        background-color: var(--token-accent-theme-1-interface-mono-interface-midground);
                    }
                }

                & > * {
                    width: 50%;
                }
            }
        }

        h2 {
            font-family: var(--font-primary);
        }

        .loadingSpinner {
            height: 70%;
        }
    }

    .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 80%;
        backdrop-filter: blur(12px);
    }
}
