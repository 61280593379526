import { useNavigateToSignup } from '@/hooks/auth';
import { setStartShare, submitArisewareScore } from '@/state/features/app';
import { useAppDispatch, useAppSelector } from '@/state/store';
import { useNavigate } from 'react-router-dom';

export default function useTitanEvents(arisewareProjectID: string) {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const sceneSelectorData = useAppSelector((state) => state.app.sceneSelectorData);
    const navigateToSignup = useNavigateToSignup();

    function onShare(data: any) {
        const { duration } = data.detail;

        const message = `I completed The Walking Dead: Solve to Survive in ${secondsToString(duration)}.
Try your luck at Surviving!
#TheWalkingDead`;

        dispatch(setStartShare(message));
    }

    // TODO: remove once goto is implemented
    function onGotoCamp() {
        const campId = process.env.PUBLIC_TITAN_CAMP_ID;
        navigate(`/location/${campId}`);
    }

    function onGoto(data: any) {
        const target = data.detail;

        function gotoPuzzle(index: number) {
            if (sceneSelectorData?.scenes?.[index]?.environmentID) {
                const id = sceneSelectorData.scenes[index].environmentID;
                navigate(`/location/${id}`);
            } else {
                console.error('Failed to go to puzzle, index does not exist:', index);
            }
        }

        switch (target) {
            case 'scene_selector':
                navigate('/selector');
                break;

            case 'supply_run':
                const supplyRunId = process.env.PUBLIC_TITAN_SUPPLY_RUN_ID;
                navigate(`/location/${supplyRunId}`);
                break;

            case 'camp':
                const campId = process.env.PUBLIC_TITAN_CAMP_ID;
                navigate(`/location/${campId}`);
                break;

            case 'puzzle1':
                gotoPuzzle(0);
                break;

            case 'puzzle2':
                gotoPuzzle(1);
                break;

            case 'puzzle3':
                gotoPuzzle(2);
                break;

            case 'signup':
                navigateToSignup();
                break;

            default:
                console.log('Unknown goto target:', target);
                break;
        }
    }

    function onSubmitResult(data: any) {
        const result = {
            arisewareProjectID,
            isCompleted: true,
            score: data?.detail?.result ?? 0,
        };
        dispatch(submitArisewareScore(result));
    }

    return {
        onShare,
        onGotoCamp,
        onGoto,
        onSubmitResult,
    };
}

function secondsToString(seconds: number) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    if (minutes < 1) {
        return `${padTime(remainingSeconds)} seconds`;
    }

    return `${padTime(minutes)}:${padTime(remainingSeconds)} minutes`;
}

function padTime(t: number) {
    return t.toString().padStart(2, '0');
}
