@import '../../style/globals.scss';

.container {
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 100%;
}

.AriseLoginModal {
    position: relative;
    width: 364px;
    height: 620px;
    background-color: var(--token-system-interface-background) !important;
    color: var(--token-system-text-default);
    font-family: Inter;
    font-size: $font-size-p;
    text-align: center;

    .backButton {
        position: absolute;
        top: 10px;
        left: 10px;
    }

    .closeButton,
    .backButton {
        svg path {
            fill: var(--token-system-interface-foreground);
        }

        &:hover {
            svg path {
                fill: var(--token-system-text-alt);
            }
        }
    }

    h2 {
        width: 100%;
        margin-bottom: 15px;
        font-size: $font-size-h6;
        font-weight: bold;
        text-align: center;
    }

    a {
        color: var(--token-system-text-alt);
        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    .logo {
        display: inline-block;
    }

    .intro {
        margin-bottom: 33px;
    }

    .smallText {
        font-size: $font-size-small;
    }

    .emailInfo {
        margin-top: 12px;
        margin-bottom: 28px;
    }

    .separator {
        display: flex;
        align-items: center;
        width: 100%;
        height: 50px;
        text-align: center;

        &:before,
        &:after {
            content: '';
            flex: 1;
            border-bottom: 1px solid var(--token-system-text-default);
            opacity: 0.3;
            margin: 2px;
        }
        &:before {
            margin-right: 20px;
        }
        &:after {
            margin-left: 20px;
        }
    }

    .loginLink {
        display: inline-block;
        color: var(--token-system-brand);
    }

    .errorMessage {
        color: var(--token-system-state-error);
        margin-top: 5px;
        text-align: center;

        &:empty::before {
            content: '';
            display: inline-block;
        }
    }

    input {
        accent-color: var(--token-system-interface-midground);
        background-color: var(--token-system-interface-midground);
        color: var(--token-system-text-default);
        border-color: var(--token-system-interface-foreground-alt);
        border-radius: 4px;

        &:focus {
            border-color: var(--token-system-interface-foreground);
        }

        &::placeholder {
            opacity: 0;
        }
    }

    input[type='checkbox']:checked {
        width: 24px;
        height: 24px;
    }

    .input {
        width: 100%;
        height: 40px;
        margin-bottom: 10px;
    }

    label {
        display: inline-block;
        width: 100%;
        margin-bottom: 5px;
        margin-left: 2px;
        text-align: left;
    }

    .checkboxContainer {
        display: flex;
        width: 100%;
        margin: 10px 0px;

        input {
            width: 24px;
            height: 24px;
            margin-right: 10px;
        }

        label {
            font-size: $font-size-small;
        }
    }

    .bottomText {
        display: inline-block;
        width: 100%;
        margin-top: 10px;
        text-align: center;
    }

    .forgotPassword {
        display: inline-block;
        width: 100%;
        margin-bottom: 10px;
        text-align: left;
    }

    .turnStyle {
        margin: 10px 0px;
    }
}
