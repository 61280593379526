import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@/state/store';
import { LS_NEWSMODAL_LAST_VERSION_SEEN_KEY } from '@/globals';
import { setNewsModalLastVersionSeen } from '@/state/features/app';
import Modal from '@/components/Modal';
import MarkdownRenderer from '@/components/MarkdownRenderer';
import { sendEvent } from '@/utils/analytics';
import { ModalData } from '@/models/misc';
import { getNewsModal } from '@/arise/api';

import * as styles from './styles.module.scss';

export default function NewsModal() {
    const dispatch = useAppDispatch();
    const newsModalLastVersionSeen = useAppSelector((state) => state.app.newsModalLastVersionSeen);
    const [isOpen, setIsOpen] = useState(false);
    const [modalData, setModalData] = useState<ModalData[]>();
    const [currentModal, setCurrentModal] = useState<ModalData>();

    async function getData() {
        try {
            const response = await getNewsModal();
            setModalData(response);
        } catch (error) {
            console.warn('Could not get News Modal data:', error);
        }
    }

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (!modalData?.length) {
            return;
        }
        checkIfModalAvailableAndShow();

        // Check every 10 seconds if we should show a new modal
        const interval = setInterval(() => {
            checkIfModalAvailableAndShow();
        }, 10000);

        return () => clearInterval(interval);
    }, [modalData, newsModalLastVersionSeen]);

    const checkIfModalAvailableAndShow = () => {
        // Only show user each modal once
        const currentModal = getCurrentModal(modalData);
        const lastVersionSeen =
            newsModalLastVersionSeen || window.localStorage.getItem(LS_NEWSMODAL_LAST_VERSION_SEEN_KEY);
        if (currentModal && currentModal.id !== lastVersionSeen) {
            setCurrentModal(currentModal);
            setIsOpen(true);
            window.localStorage.setItem(LS_NEWSMODAL_LAST_VERSION_SEEN_KEY, currentModal.id);
            dispatch(setNewsModalLastVersionSeen(currentModal.id));
            sendEvent('newsmodal_shown', { version: currentModal.id });
        }
    };

    if (!currentModal?.markdown) return;

    return (
        <Modal
            zIndex={1000}
            modalClassname={styles.NewsModal}
            onClose={() => {
                setIsOpen(false);
                sendEvent('newsmodal_closed', { version: currentModal.id });
            }}
            isVisible={isOpen}
            canScroll={true}
            leaveSpaceAboveAndBelow={false}
            contentClassname={styles.content}
        >
            <MarkdownRenderer base64MarkdownContent={currentModal.markdown} textAlign="center" />
        </Modal>
    );
}

// Modals are ordered in reverse chronological order (e.g. 2025 before 2024).
const getCurrentModal = (modalData: ModalData[]) => {
    // Remove modals which are in the future
    const pastModals = modalData?.filter((modal) => new Date(modal.startsDate) < new Date());
    // Next modal in array must be most recent one in the past
    return pastModals?.[pastModals?.length - 1];
};
