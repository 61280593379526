import { BanReason } from '@/models/profile';

export const BAN_MESSAGE_SUBTITUTION_STRING = '{time}';
export const BAN_MESSAGE_FALLBACK = 'You have been frozen for {time}.';
export const BAN_MESSAGE_TIME_FALLBACK = 'a few moments';

export type BanMessages = {
    [key in BanReason]: string;
};

export const BAN_MESSAGES_UNTHEMED: BanMessages = {
    short_ban: `You have been frozen. In {time} you'll be back in!`,
    permanent_ban: `You have been banned.`,
    rate_limit_exceeded: `You are sending too many messages. In {time} you'll be back in!`,
};
