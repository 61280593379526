import * as api from '@/arise/api';
import { useEffect, useState } from 'react';

// AuthGate makes sure everyone is logged in, and if they are not it logs them in as a guest
export default function AutoAuthGate({ children }: { children: React.ReactNode }) {
    // Do run on /auth routes
    // set checked to default on these routes
    const checkedDefault = window.location.pathname.startsWith('/auth');

    const [checked, setChecked] = useState(checkedDefault);

    useEffect(() => {
        (async () => {
            const authToken = api.getAuthToken();
            if (!checked && !authToken) {
                try {
                    await api.loginAsGuest();
                    setChecked(true);
                } catch (e) {
                    console.error(e);
                }
            } else {
                setChecked(true);
            }
        })();
    }, []);

    return checked ? <>{children}</> : null;
}
