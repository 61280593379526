import { useEffect, useRef, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@/state/store';
import { setAuthModalScreen } from '@/state/features/app';
import Modal from '@/components/Modal';
import IconButton from '@/components/IconButton';
import { isInsidersInstance } from '@/theme/vars';

import SignupLanding from './pages/SignupLanding';
import EmailSignup from './pages/EmailSignup';
import Login from './pages/Login';
import ForgottenPassword from './pages/ForgottenPassword';
import * as styles from './styles.module.scss';

export type AriseAuthScreen = 'signupLanding' | 'emailSignup' | 'socialSignup' | 'login' | 'forgottenPassword';
export interface AriseAuthScreenProps {
    navigateToScreen: (screen: AriseAuthScreen) => void;
    closeModal: () => void;
}

export default function AriseSignupModal() {
    const dispatch = useAppDispatch();
    const authModalScreen = useAppSelector((state) => state.app.authModalScreen);
    const [uiStatus, setUiStatus] = useState<AriseAuthScreen>(authModalScreen);
    const uiStatusHistory = useRef([]);

    useEffect(() => {
        if (!authModalScreen) {
            uiStatusHistory.current = [];
        }
        setUiStatus(authModalScreen);
    }, [authModalScreen]);

    function navigateToScreen(screen: AriseAuthScreen) {
        uiStatusHistory.current?.push(uiStatus);
        setUiStatus(screen);
    }

    function closeModal() {
        dispatch(setAuthModalScreen(null));
    }

    function goBack() {
        const previousScreen = uiStatusHistory.current?.pop();
        if (previousScreen) {
            setUiStatus(previousScreen);
        }
    }

    const shouldShowBackButton = !!uiStatusHistory.current?.length;

    const getContent = () => {
        switch (uiStatus) {
            case 'signupLanding':
                return <SignupLanding navigateToScreen={navigateToScreen} closeModal={closeModal} />;

            case 'emailSignup':
                return <EmailSignup navigateToScreen={navigateToScreen} closeModal={closeModal} />;

            case 'login':
                return <Login navigateToScreen={navigateToScreen} closeModal={closeModal} />;

            case 'forgottenPassword':
                return <ForgottenPassword navigateToScreen={navigateToScreen} closeModal={closeModal} />;

            default:
                return <SignupLanding navigateToScreen={navigateToScreen} closeModal={closeModal} />;
        }
    };

    if (isInsidersInstance) return null; // Todo - fix modal being visible below content
    return (
        <Modal
            classname={styles.container}
            modalClassname={styles.AriseLoginModal}
            closeButtonClassname={styles.closeButton}
            onClose={closeModal}
            isVisible={!!authModalScreen}
            leaveSpaceAboveAndBelow={false}
            canScroll
            closeButtonStyle="icon"
            shouldCloseOnExternalClick={false}
        >
            {shouldShowBackButton && (
                <IconButton
                    iconType="arrow-back"
                    onClick={goBack}
                    buttonStyle="icon"
                    ariaLabel="back"
                    className={styles.backButton}
                />
            )}
            <img className={styles.logo} alt="Those Beyond Logo" src="/assets/global/those-beyond-icon.svg"></img>
            {getContent()}
        </Modal>
    );
}
