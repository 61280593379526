import classNames from 'classnames';
import { useTheme } from '@/theme';
import * as styles from './styles.module.scss';

interface FailOverlayProps {
    failed: boolean;
}

export default function FailOverlay({ failed }: FailOverlayProps) {
    const theme = useTheme();
    const imgPath = theme.customJSON.failScreenOverlay;

    return (
        <>
            <div
                className={classNames(styles.FailOverlayBg, {
                    [styles.failed]: failed,
                })}
            ></div>
            <div
                className={classNames(styles.FailOverlay, {
                    [styles.failed]: failed,
                })}
            >
                {imgPath && <img src={imgPath} alt="Failed" nopin="nopin" />}
            </div>
        </>
    );
}
