import classNames from 'classnames';
import { useTheme } from '@/theme';
import * as styles from './styles.module.scss';

// Visual only component

interface ThemedMenuBarProps {
    children?: React.ReactNode;
    classname?: string;
}

export default function ThemedMenuBar({ children, classname }: ThemedMenuBarProps) {
    const theme = useTheme();

    const hasImages = theme.customJSON.floatingHeaderLeftCapImageUrl && theme.customJSON.floatingHeaderCenterImageUrl;

    if (!hasImages)
        return <div className={classNames(styles.ThemedMenuBar, styles.noBackgroundImage, classname)}>{children}</div>;

    return (
        <div className={classNames(styles.ThemedMenuBar, classname)}>
            <img
                aria-hidden="true"
                aria-label=" "
                className={styles.leftCap}
                src={theme.customJSON.floatingHeaderLeftCapImageUrl}
            />
            <div
                className={styles.center}
                style={{
                    backgroundImage: `url(${theme.customJSON.floatingHeaderCenterImageUrl})`,
                }}
            ></div>
            <img
                aria-hidden="true"
                aria-label=" "
                className={styles.rightCap}
                src={theme.customJSON.floatingHeaderLeftCapImageUrl}
            />
            {children}
        </div>
    );
}
