@import '../../style/globals.scss';

.ThemedMenuBar {
    position: relative;
    display: flex;
    width: 100%;
    height: 50px;
    filter: drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.6));
    user-select: none;

    &.noBackgroundImage {
        background-color: var(--token-accent-theme-1-interface-mono-interface-background);
        border-radius: 10px;
    }

    .leftCap,
    .center,
    .rightCap {
        height: 100%;
    }

    .rightCap {
        transform: scaleX(-1);
    }

    .center {
        width: 100%;
    }
}
