import { useInitialAuthCheck } from '@/hooks/auth';
import { homePath } from '@/theme/vars';

export default function HomePage() {
    useInitialAuthCheck({
        loggedInRedirect: homePath,
    });

    return <div className="Home"></div>;
}
