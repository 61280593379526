import { AuthButton, ExternalLoginButtons } from '../../components/LoginButton';
import { ButtonLink } from '../../components/ButtonLink';
import { AriseAuthScreenProps } from '../..';
import * as styles from '../../styles.module.scss';

export default function SignupLanding({ navigateToScreen }: AriseAuthScreenProps) {
    return (
        <div>
            <h2>Join Those Beyond</h2>
            <p>Creating an account allows you to participate in chat and gain access to exclusive content.</p>
            <br />
            <ExternalLoginButtons />
            <div className={styles.separator}>or</div>
            <AuthButton text="Continue with email" onClick={() => navigateToScreen('emailSignup')} />
            <br />
            <p className={styles.smallText}>
                By signing up, you agree to the <a href="/">Terms of Service</a> and <a href="/">Privacy Policy</a>,
                including <a href="/">Cookie Use</a>.
            </p>
            <br />
            <span>
                Have an account?{' '}
                <ButtonLink isHighlighted onClick={() => navigateToScreen('login')}>
                    Log in
                </ButtonLink>
            </span>
        </div>
    );
}
