@import '../../../style/globals.scss';

.PinnedMessage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 17px 0 50px 0;
    background: linear-gradient(0deg, transparent 0%, black 50%);
    pointer-events: none;
    animation: fadeIn 0.5s ease-in-out forwards;

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    .message {
        position: relative;
        display: flex;
        background-color: var(--token-arise-theme-1-state-state-success-dark);
        font-family: var(--font-tertiary);
        font-size: $font-size-p;
        word-break: break-word;
        border-radius: 10px;
        pointer-events: auto;

        width: calc(100% - $chat-padding-m - $chat-padding-m);
        margin: 0 $chat-padding-m;

        @media (max-width: $bp-s) {
            margin: 0 $chat-padding-s;
            width: calc(100% - $chat-padding-s - $chat-padding-s);
        }

        @media (max-width: $bp-xs) {
            margin: 0 $chat-padding-xs;
            width: calc(100% - $chat-padding-xs - $chat-padding-xs);
        }

        .badge {
            position: absolute;
            top: -10px;
            left: 10px;
            height: 21px;
            padding: 0px 8px;
            background-color: var(--token-arise-theme-1-state-state-success-mid);
            color: var(--token-arise-theme-1-state-state-success-light);
            font-size: $font-size-p;
            font-weight: bold;
            border-radius: 30px;

            svg {
                display: inline-block;
                height: 16px;
                margin-right: 2px;
                vertical-align: sub;

                path {
                    fill: var(--token-arise-theme-1-state-state-success-light);
                }
            }
        }

        .text {
            height: 100%;
            width: 100%;
            padding: 20px 15px 15px 15px;

            .title {
                color: var(--token-arise-theme-1-state-state-success-light);
                font-weight: bold;
            }

            a {
                color: var(--token-accent-theme-1-text-text-link);

                &:hover,
                &:focus {
                    text-decoration: underline;
                }
            }
        }

        .image {
            width: 200px;
            margin-left: -15px;
            background-color: var(--token-LEGACY-accent-theme-1-interface-interface-background);
            background-position: center;
            background-size: cover;
            mask-image: url('/assets/global/images/slanted-image-mask.webp');
            mask-size: 100% 100%;
            border-radius: 10px;
        }
    }
}
