import { useNavigate } from 'react-router-dom';

import { exitModalText, homePath } from '@/theme/vars';
import { setIsExitLocationModalOpen, setIsSnackbarVisible, setShouldShowGuestSignUpModal } from '@/state/features/app';
import { useAppDispatch, useAppSelector } from '@/state/store';
import { useAudio } from '@/hooks/audio';
import Modal from '@/components/Modal';
import ThemedButton from '@/components/ThemedButton';

export default function ExitModal() {
    const { audio } = useAudio();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isExitLocationModalOpen = useAppSelector((state) => state.app.isExitLocationModalOpen);
    return (
        <Modal
            title="Head back?"
            onClose={() => {
                dispatch(setIsExitLocationModalOpen(false));
                dispatch(setIsSnackbarVisible(true));
            }}
            isVisible={isExitLocationModalOpen}
        >
            <ThemedButton
                text={exitModalText}
                onClick={() => {
                    dispatch(setIsExitLocationModalOpen(false));
                    dispatch(setShouldShowGuestSignUpModal(true));
                    navigate(homePath);
                    audio.play('slam');
                    audio.play('wipe-dark1');
                }}
                disabled={!isExitLocationModalOpen}
            />
        </Modal>
    );
}
