@import '../../../style/globals.scss';

.GuestFeedInputOverlay {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: auto;
    min-height: 62px;
    padding: 10px 198px 10px 15px;
    background-color: var(--token-system-interface-background);
    color: var(--token-system-text-default);
    font-size: $font-size-p;
    border-radius: 10px;

    .buttons {
        position: absolute;
        right: 11px;
        width: auto;
        height: 40px;
    }

    span {
        margin-right: 5px;
    }

    .loginButton {
        position: relative;
        display: inline-block;
        width: 82px;
        height: 40px;
        padding: 0;
        background-color: var(--token-system-brand);

        &:hover {
            filter: brightness(1.5);
        }

        &:first-child {
            margin-right: 11px;
            background-color: var(--token-system-brand-mono);
        }
    }
}
