import classNames from 'classnames';

import { Rarity } from '@/models/profile';
import Icon, { IconTypes } from '@/components/Icon';

import * as styles from './styles.module.scss';

interface CardDescriptionProps {
    title: string;
    classname?: string;
    onClick?: () => void;
    subtitle?: string;
    backgroundImage?: string;
    maskImage?: string;
    rarity?: Rarity;
    isSelected?: boolean;
    iconType?: IconTypes;
    keepFontSize?: boolean; // Stops font size from getting changed based on string length
}

export default function CardDescription({
    title,
    classname,
    onClick,
    subtitle,
    backgroundImage,
    maskImage,
    rarity,
    isSelected,
    iconType,
    keepFontSize,
}: CardDescriptionProps) {
    const classes = classNames(styles.CardDescription, classname, styles[rarity?.toLowerCase()], {
        [styles.isButton]: onClick,
    });
    const style = {
        ...(backgroundImage && { backgroundImage: `url("${backgroundImage}")` }),
        ...(maskImage && { maskImage: `url("${maskImage}")` }),
    };
    const content = (
        <>
            <p className={classNames(styles.title, { [styles.longTitle]: !keepFontSize && title?.length > 13 })}>
                {title}
            </p>
            <p className={styles.subtitle}>{subtitle}</p>
            {iconType && <Icon type={iconType} className={styles.icon} />}
        </>
    );
    if (onClick) {
        return (
            <button onClick={onClick} className={classes} style={style} aria-selected={isSelected}>
                {content}
            </button>
        );
    }
    return (
        <div className={classes} style={style}>
            {content}
        </div>
    );
}
