import { oAuthExchange } from '@/arise/api';
import { postLogin } from '@/state/features/auth';
import { useAppDispatch, useAppSelector } from '@/state/store';
import { useEffect } from 'react';

export default function OAuthExchangePage() {
    const dispatch = useAppDispatch();
    const auth = useAppSelector((state) => state.auth);
    // todo - way of setting display name
    useEffect(() => {
        (async () => {
            const query = window.location.search;
            const token = new URLSearchParams(query).get('token');
            if (!token) {
                console.error('No token found');
                window.location.href = '/';
                return;
            }
            try {
                await oAuthExchange(token);
                dispatch(postLogin());
            } catch (err) {
                console.error(err);
                window.location.href = '/';
            }
        })();
    }, []);

    useEffect(() => {
        if (auth.loggedIn) {
            window.location.href = '/';
        }
    }, [auth.loggedIn]);
    return <div></div>;
}
