import Button from '@/components/Forms/Button';
import { useNavigateToLogin, useNavigateToSignup } from '@/hooks/auth';
import { sendEvent } from '@/utils/analytics';
import { useTheme } from '@/theme';

import * as styles from './styles.module.scss';

export default function GuestInputOverlay() {
    const FALLBACK_TEXT = 'Join to get the best experience.';
    const theme = useTheme();
    const navigateToSignup = useNavigateToSignup();
    const navigateToLogin = useNavigateToLogin();
    const text = theme.customJSON.guestFeedInputOverlayText ?? FALLBACK_TEXT;

    return (
        <div className={styles.GuestFeedInputOverlay}>
            <span>{text}</span>
            <div className={styles.buttons}>
                <Button
                    className={styles.loginButton}
                    onClick={() => {
                        sendEvent('guest_chat_overlay_log_in_click', {
                            message: text,
                        });
                        navigateToLogin();
                    }}
                >
                    Log In
                </Button>
                <Button
                    className={styles.loginButton}
                    onClick={() => {
                        sendEvent('guest_chat_overlay_sign_up_click', {
                            message: text,
                        });
                        navigateToSignup();
                    }}
                >
                    Sign Up
                </Button>
            </div>
        </div>
    );
}
