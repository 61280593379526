import { useState } from 'react';

import { passwordReset } from '@/arise/api';
import Input from '@/components/Forms/Input';
import { isValidEmail } from '@/utils/validation';
import { SUPPORT_EMAIL } from '@/theme/vars';

import { AriseAuthScreenProps } from '../..';
import { AuthButton } from '../../components/LoginButton';
import * as styles from '../../styles.module.scss';

export default function ForgottenPassword({ navigateToScreen }: AriseAuthScreenProps) {
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [uiStatus, setUiStatus] = useState<'enterEmail' | 'emailSent'>('enterEmail');

    async function onSubmit() {
        if (!isValidEmail(email)) {
            setErrorMessage('Please enter a valid email address');
            return;
        }
        try {
            await passwordReset(email);
            setUiStatus('emailSent');
        } catch (error) {
            setErrorMessage(`Could not reset password, please contact ${SUPPORT_EMAIL}`);
            console.error('Could not reset password', error);
        }
    }

    const EnterEmail = (
        <div>
            <h2>Find your Those Beyond account</h2>
            <p>Enter the email associated with your account to change your password.</p>
            <label htmlFor="arise-forgotten-email">Email</label>
            <Input
                className={styles.input}
                id="arise-forgotten-email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email Address"
                autoComplete="email"
            />
            <AuthButton disabled={!email} text="Continue" onClick={onSubmit} style={{ marginTop: 10 }} />
            <p className={styles.errorMessage}>{errorMessage}</p>
        </div>
    );

    const EmailSent = (
        <div>
            <h2>Email Sent</h2>
            <p>
                We sent an email to {email}. If this email is connected to a Those Beyond account, you'll be able to
                reset your password.
            </p>
            <br />
            <p>
                Didn't get the email? Try these tips from our <a href="/">Help Centre.</a>
            </p>
            <br />
            <AuthButton text="Back to Login" onClick={() => navigateToScreen('login')} />
            {/* Try again button here? */}
        </div>
    );
    return uiStatus === 'enterEmail' ? EnterEmail : EmailSent;
}
