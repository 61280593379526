import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { AssetPack } from '@/models/shop';
import { useTheme } from '@/theme';
import { useCurrentUserIsGuest } from '@/hooks/users';
import { pathToURL } from '@/utils/urls';
import { getPriceString } from '@/utils/currency';
import { purchaseAssetPack } from '@/utils/shop';
import { sendEvent } from '@/utils/analytics';
import ItemContainer from '@/components/ItemContainer';
import Button from '@/components/Forms/Button';
import CardDescription from '@/components/CardDescription';
import IconButton from '@/components/IconButton';
import { useNavigateToSignup } from '@/hooks/auth';

import * as styles from './styles.module.scss';

interface BundleModalProps {
    classname?: string;
    onClose: () => void;
    pack: AssetPack;
}

export const BundleModal = ({ onClose, classname, pack }: BundleModalProps) => {
    const theme = useTheme();
    const isGuest = useCurrentUserIsGuest();
    const navigateToSignup = useNavigateToSignup();
    const closeButtonRef = useRef<HTMLButtonElement>(null);
    const [transactionStatus, setTransactionStatus] = useState<'default' | 'loading' | 'failed'>('default');
    const [shouldPlayCloseAnimation, setShouldPlayCloseAnimation] = useState(false);

    useEffect(() => {
        closeButtonRef.current?.focus();
    }, []);

    const close = () => {
        setShouldPlayCloseAnimation(true);
        sendEvent('profile_shop_bundle_close', { id: pack?.id, name: pack?.name, price: pack?.price });
        setTimeout(() => {
            onClose();
        }, 400); // Should match close animation duration
    };

    if (!pack) return;
    const { background, character, avatarFrame } = pack.assets;

    const purchase = () => {
        setTransactionStatus('loading');
        purchaseAssetPack(pack.id).then((wasSuccessful) => {
            if (!wasSuccessful) {
                setTransactionStatus('failed');
            }
        });
        sendEvent('profile_shop_purchase_initiated', { id: pack?.id, name: pack?.name, price: pack?.price });
    };

    const sharedCardProps = {
        backgroundImagePath: theme.customJSON?.cardBackgroundImageUrl,
        textBackgroundImagePath: theme.customJSON?.cardTextBackgroundImageUrl,
        hasBorder: false,
    };

    const renderBuyButton = () => {
        switch (transactionStatus) {
            case 'loading':
                return <Button disabled={true}>Processing...</Button>;
            case 'failed':
                return <Button disabled={true}>Purchase failed</Button>;
            default:
                const formattedPrice = getPriceString(pack.currency, pack.price);
                return (
                    <Button onClick={purchase} disabled={!formattedPrice || isGuest}>
                        {formattedPrice ? 'Buy for ' + formattedPrice : 'Unavailable'}
                    </Button>
                );
        }
    };

    return (
        <div
            className={classNames(styles.BundleModal, classname, { [styles.closeAnimation]: shouldPlayCloseAnimation })}
            inert={shouldPlayCloseAnimation ? '' : undefined}
        >
            <div
                className={classNames(styles.modal, {
                    [styles.noBackgroundImage]: !theme.customJSON?.profileBackground,
                })}
                style={
                    theme.customJSON?.profileBackground
                        ? { backgroundImage: `url("${theme.customJSON?.profileBackground}")` }
                        : {}
                }
            >
                <IconButton
                    buttonRef={closeButtonRef}
                    iconType="close"
                    buttonStyle="round"
                    ariaLabel="close modal"
                    className={styles.closeButton}
                    onClick={close}
                />
                <div className={styles.scrollContainer}>
                    <h2 className={styles.title}>{pack.name} includes:</h2>
                    <ul className={styles.assets}>
                        <li>
                            <ItemContainer
                                classname={styles.frameItem}
                                imagePath={pathToURL(avatarFrame.imagePath)}
                                title={avatarFrame.name}
                                subtitle="Frame"
                                onSelected={() => {}}
                                {...sharedCardProps}
                            />
                        </li>
                        <li>
                            <ItemContainer
                                classname={styles.characterItem}
                                imagePath={pathToURL(character.thumbnailPath)}
                                title={character.name}
                                subtitle="Character"
                                onSelected={() => {}}
                                {...sharedCardProps}
                            />
                        </li>
                        <li>
                            <ItemContainer
                                classname={styles.backgroundItem}
                                imagePath={pathToURL(background.thumbnailPath)}
                                title={background.name}
                                subtitle="Background"
                                onSelected={() => {}}
                                {...sharedCardProps}
                            />
                        </li>
                        <li>
                            {sharedCardProps.backgroundImagePath ? (
                                <img
                                    aria-hidden="true"
                                    className={styles.blankItem}
                                    src={sharedCardProps.backgroundImagePath}
                                    alt=" "
                                ></img>
                            ) : (
                                <div className={classNames(styles.blankItem, styles.noBackgroundImage)}></div>
                            )}
                        </li>
                    </ul>
                    <div className={styles.interactionContainer}>
                        <CardDescription
                            title={pack.name}
                            subtitle={pack.rarity}
                            rarity={pack.rarity}
                            maskImage={theme.customJSON?.cardTextBackgroundImageUrl}
                        />
                        {renderBuyButton()}
                    </div>
                    <p className={styles.bottomText}>
                        Once purchased, decorations will be added to your profile collection and can be applied at any
                        time.
                    </p>
                </div>
                {isGuest && (
                    <div className={styles.guestOverlay}>
                        <h2>Sign Up to check out</h2>
                        <p>You must be logged in to buy items from the shop.</p>
                        <Button
                            onClick={() => {
                                sendEvent('shop_bundle_modal_sign_up_click', {
                                    message: 'Sign Up to check out, You must be logged in to buy items from the shop.',
                                });
                                navigateToSignup();
                            }}
                        >
                            Sign Up
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default BundleModal;
