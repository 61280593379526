import { useState, useEffect } from 'react';

import Input from '@/components/Forms/Input';
import { useAppDispatch, useAppSelector } from '@/state/store';
import { isValidEmail } from '@/utils/validation';
import { login, register } from '@/state/features/auth';

import { AuthButton } from '../../components/LoginButton';
import { ButtonLink } from '../../components/ButtonLink';
import { Turnstile } from '../../components/Turnstile';
import { AriseAuthScreenProps } from '../..';
import { loadCurrentUserProfile } from '@/state/features/profile';
import * as styles from '../../styles.module.scss';

export default function EmailSignup({ navigateToScreen, closeModal }: AriseAuthScreenProps) {
    const auth = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();

    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [agreementChecked, setAgreementChecked] = useState(false);
    const [turnstileToken, setTurnstileToken] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    function onSubmit() {
        if (auth.isRegistering) return;

        // TODO: Validate password
        if (!isValidEmail(email)) {
            setErrorMessage('Please enter a valid email address');
            // todo show error messages from backend
            return;
        }

        if (!email || !username || !password) return;
        dispatch(register([username, email, password, turnstileToken]));
    }

    useEffect(() => {
        // Registered successfully, now log user in
        if (auth.isRegistrationSuccessful) {
            dispatch(login([email, password]));
        }
    }, [auth.isRegistrationSuccessful]);

    useEffect(() => {
        // Failed to register, show error
        if (auth.registerError) {
            setErrorMessage(auth.registerError);
        }
    }, [auth.registerError]);

    useEffect(() => {
        // User is registered and logged in, load their profile and close modal
        if (auth.user?.email === email) {
            dispatch(loadCurrentUserProfile([auth.user.id]));
            closeModal();
        }
    }, [auth.loggedIn, auth.user?.email]);

    return (
        <div>
            <h2>Enter your details</h2>
            <label htmlFor="arise-registration-email">Email</label>
            <Input
                className={styles.input}
                id="arise-registration-email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email Address"
                autoComplete="email"
            />
            <label htmlFor="arise-registration-password">Password</label>
            <Input
                className={styles.input}
                id="arise-registration-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                type="password"
                autoComplete="new-password"
            />
            <label htmlFor="arise-registration-username">Username</label>
            <Input
                className={styles.input}
                id="arise-registration-username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Username"
                autoComplete="username"
            />
            <div className={styles.checkboxContainer}>
                <input
                    type="checkbox"
                    id="arise-registration-terms-check"
                    checked={agreementChecked}
                    onChange={(e) => setAgreementChecked(e.target.checked)}
                ></input>
                <label htmlFor="arise-registration-terms-check">
                    I have read and agree to <a href="/">Those Beyond’s Terms of Service</a> and{' '}
                    <a href="/">Privacy Policy.</a>
                </label>
            </div>
            <Turnstile
                className={styles.turnStyle}
                onToken={(token: string) => setTurnstileToken(token)}
                onError={() => setErrorMessage('Verification failed. Please try again or refresh the page.')}
            />
            <AuthButton
                disabled={!(email && username && password && agreementChecked && turnstileToken)}
                text="Continue"
                onClick={onSubmit}
            />
            <span className={styles.bottomText}>
                Have an account?{' '}
                <ButtonLink isHighlighted onClick={() => navigateToScreen('login')}>
                    Log in
                </ButtonLink>
            </span>
            <p className={styles.errorMessage}>{errorMessage}</p>
        </div>
    );
}
