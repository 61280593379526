@import '../../../../style/globals.scss';

.ButtonLink {
    display: inline;
    background: none;
    padding: 0;
    margin: 0;

    &.highlighted {
        color: var(--token-system-brand);
    }

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}
