import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { useTheme } from '@/theme';
import { pathToURL } from '@/utils/urls';
import { processTitles, setProfileAsset, unsetProfileAsset } from '@/utils/profile';
import { sendEvent } from '@/utils/analytics';
import * as arise from '@/arise/api';
import { useAppDispatch, useAppSelector } from '@/state/store';
import { setProfileFaction, setProfileTitle } from '@/state/features/profile';
import { setIsShopOpen } from '@/state/features/app';
import { AvatarFrame, Background, Character, Faction, Title, UserOwnedAssets } from '@/models/profile';
import IconButton from '@/components/IconButton';
import { IconTypes } from '@/components/Icon';
import ItemContainer from '@/components/ItemContainer';
import LoadingScreen from '@/components/LoadingScreen';
import CardDescription from '@/components/CardDescription';

import * as styles from './styles.module.scss';

enum TabType {
    Character = 'Character',
    Title = 'Title',
    Background = 'Background',
    Faction = 'Faction',
    Frame = 'Frame',
}

const ProfileEditor = () => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const currentUserProfile = useAppSelector((state) => state.profile.currentUserProfile);

    const [currentTab, setCurrentTab] = useState<TabType>(TabType.Character);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [characters, setCharacters] = useState<Character[]>([]);
    const [titles, setTitles] = useState<Title[]>();
    const [backgrounds, setBackgrounds] = useState<Background[]>();
    const [factions, setFactions] = useState<Faction[]>();
    const [frames, setFrames] = useState<AvatarFrame[]>();

    const loadElements = () => {
        const promises: [Promise<Title[]>, Promise<Faction[]>, Promise<UserOwnedAssets>] = [
            arise.getTitles(currentUserProfile?.userID),
            arise.getFactions(),
            arise.getUserOwnedAssets(),
        ];

        Promise.all(promises)
            .then(([titlesData, factionsData, ownedAssetsData]) => {
                setCharacters(ownedAssetsData.characters);
                setFrames(ownedAssetsData.avatarFrames);
                setBackgrounds(ownedAssetsData.backgrounds);
                setTitles(processTitles(titlesData));
                setFactions(factionsData);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
                console.error('Error loading profile editor');
            });
    };

    useEffect(() => {
        if (currentUserProfile?.userID) {
            loadElements();
            sendEvent('profile_editor_open');
        }
    }, [currentUserProfile?.userID]);

    const TabButton = ({ tabType, iconType }: { tabType: TabType; iconType: IconTypes }) => (
        <li>
            <IconButton
                className={classNames({ [styles.isActive]: currentTab === tabType })}
                iconType={iconType}
                ariaLabel={tabType}
                buttonStyle="icon"
                onClick={() => {
                    setCurrentTab(tabType);
                    sendEvent(`profile_editor_view_tab_${tabType?.toLocaleLowerCase()}`);
                }}
                title={tabType}
            />
        </li>
    );

    const sharedCardProps = {
        backgroundImagePath: theme.customJSON?.cardBackgroundImageUrl,
        textBackgroundImagePath: theme.customJSON?.cardTextBackgroundImageUrl,
        hasBorder: false,
        interactable: true,
    };

    const shopLinkCard = (
        <li>
            <ItemContainer
                title="Head to shop"
                backgroundIconType="shop"
                onSelected={() => {
                    dispatch(setIsShopOpen(true));
                    sendEvent('open_shop_from_profile_editor');
                }}
                {...sharedCardProps}
            />
        </li>
    );

    const renderItems = () => {
        const Empty = ({ text }) => <div className={styles.empty}>{text}</div>;

        switch (currentTab) {
            case TabType.Title:
                if (!titles?.length) {
                    return <Empty text="No titles to show" />;
                }
                return titles?.map((item, index: number) => {
                    const isCurrent = currentUserProfile?.title?.id === item.titleID;
                    return (
                        <li
                            key={index + currentTab}
                            className={classNames({
                                [styles.isCurrent]: isCurrent,
                            })}
                        >
                            <CardDescription
                                classname={styles.titleItem}
                                title={item.title}
                                subtitle={item.rarity}
                                rarity={item.rarity}
                                onClick={() => {
                                    if (!isCurrent) {
                                        dispatch(setProfileTitle(item.titleID));
                                        sendEvent('profile_editor_set_title', {
                                            id: item?.titleID,
                                            name: item?.title,
                                        });
                                    }
                                }}
                                maskImage={sharedCardProps.textBackgroundImagePath}
                                isSelected={isCurrent}
                            />
                        </li>
                    );
                });

            case TabType.Faction:
                if (!factions?.length) {
                    return <Empty text="No factions to show" />;
                }
                return factions?.map((item, index: number) => {
                    const isCurrent = currentUserProfile?.factionID === item.id;
                    return (
                        <li key={index + currentTab}>
                            <ItemContainer
                                classname={classNames(styles.factionItem, {
                                    [styles.isCurrent]: isCurrent,
                                })}
                                imagePath={pathToURL(item.imagePath)}
                                title={item.name}
                                subtitle="Faction"
                                onSelected={() => {
                                    if (!isCurrent) {
                                        dispatch(setProfileFaction(item.id));
                                        sendEvent('profile_editor_set_faction', {
                                            id: item?.id,
                                            name: item?.name,
                                        });
                                    }
                                }}
                                isSelected={isCurrent}
                                {...sharedCardProps}
                            />
                        </li>
                    );
                });

            case TabType.Character:
                if (!characters?.length) {
                    return <Empty text="No characters to show" />;
                }
                return characters?.map((item, index: number) => {
                    const isCurrent = currentUserProfile?.character?.id === item.id;
                    return (
                        <li key={index + currentTab}>
                            <ItemContainer
                                classname={classNames(styles.characterItem, {
                                    [styles.isCurrent]: isCurrent,
                                })}
                                imagePath={pathToURL(item.thumbnailPath)}
                                title={item.name}
                                subtitle="Character"
                                onSelected={() => {
                                    if (!isCurrent) {
                                        setProfileAsset('character', item.id);
                                        sendEvent('profile_editor_set_character', {
                                            id: item?.id,
                                            name: item?.name,
                                        });
                                    }
                                }}
                                isSelected={isCurrent}
                                {...sharedCardProps}
                            />
                        </li>
                    );
                });

            case TabType.Background:
                if (!backgrounds?.length) {
                    return <Empty text="No backgrounds to show" />;
                }
                return backgrounds?.map((item, index: number) => {
                    const isCurrent = currentUserProfile?.background?.id === item.id;
                    return (
                        <li key={index + currentTab}>
                            <ItemContainer
                                classname={classNames(styles.backgroundItem, {
                                    [styles.isCurrent]: isCurrent,
                                })}
                                imagePath={pathToURL(item.thumbnailPath)}
                                title={item.name}
                                subtitle="Background"
                                onSelected={() => {
                                    if (!isCurrent) {
                                        setProfileAsset('background', item.id);
                                        sendEvent('profile_editor_set_background', {
                                            id: item?.id,
                                            name: item?.name,
                                        });
                                    }
                                }}
                                isSelected={isCurrent}
                                {...sharedCardProps}
                            />
                        </li>
                    );
                });

            case TabType.Frame:
                if (!frames?.length) {
                    return <Empty text="No avatar frames to show" />;
                }
                return frames?.map((item, index: number) => {
                    const isCurrent = currentUserProfile?.avatarFrame?.id === item.id;
                    return (
                        <li key={index + currentTab}>
                            <ItemContainer
                                classname={classNames(styles.frameItem, {
                                    [styles.isCurrent]: isCurrent,
                                })}
                                imagePath={pathToURL(item.imagePath)}
                                title={item.name}
                                subtitle="Frame"
                                onSelected={() => {
                                    if (isCurrent) {
                                        unsetProfileAsset('avatar_frame');
                                        sendEvent('profile_editor_remove_frame', {
                                            id: item?.id,
                                            name: item?.name,
                                        });
                                    } else {
                                        setProfileAsset('avatar_frame', item.id);
                                        sendEvent('profile_editor_set_frame', {
                                            id: item?.id,
                                            name: item?.name,
                                        });
                                    }
                                }}
                                isSelected={isCurrent}
                                {...sharedCardProps}
                            />
                        </li>
                    );
                });

            default:
                return null;
        }
    };

    const profileBackground = theme.customJSON?.profileBackground;
    const shouldShowShopLinkCard =
        theme.metadata?.hasProfileShop &&
        (currentTab === TabType.Background || currentTab === TabType.Character || currentTab === TabType.Frame);

    return (
        <div className={styles.ProfileEditor}>
            <div className={styles.top}>
                <h2 className={styles.title}>Edit Character</h2>
            </div>
            <div className={styles.bottom}>
                <div
                    className={classNames(styles.editorContainer, {
                        [styles.noBackgroundImage]: !profileBackground,
                    })}
                    style={
                        profileBackground
                            ? {
                                  backgroundImage: `url("${profileBackground}")`,
                                  borderImageSource: `url("${profileBackground}")`,
                              }
                            : {}
                    }
                >
                    {isLoading ? (
                        <LoadingScreen show={isLoading} classname={styles.loadingScreen} hasBackground={false} />
                    ) : (
                        <>
                            <ul className={styles.tabButtons}>
                                <TabButton tabType={TabType.Character} iconType="character" />
                                <TabButton tabType={TabType.Title} iconType="crown" />
                                <TabButton tabType={TabType.Background} iconType="backdrop" />
                                <TabButton tabType={TabType.Faction} iconType="faction" />
                                <TabButton tabType={TabType.Frame} iconType="avatar" />
                            </ul>
                            <h2 className={styles.tabTitle}>Edit {currentTab}</h2>
                            <ul className={styles.items}>
                                {renderItems()}
                                {shouldShowShopLinkCard && shopLinkCard}
                            </ul>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProfileEditor;
