import { useAppSelector } from '@/state/store';
import Icon from '@/components/Icon';
import MarkdownRenderer from '@/components/MarkdownRenderer';
import { pathToURL } from '@/utils/urls';

import * as styles from './styles.module.scss';

export default function PinnedMessage() {
    const location = useAppSelector((state) => state.app.location);
    const { pinnedMessageTitle, pinnedMessageMarkdown, pinnedMessageImagePath, pinnedMessageIsVisible } =
        location || {};

    if (!pinnedMessageIsVisible || (!pinnedMessageTitle && !pinnedMessageMarkdown)) return null;

    return (
        <div className={styles.PinnedMessage}>
            <div className={styles.message}>
                <div className={styles.badge}>
                    <Icon type="pin" />
                    Pinned
                </div>
                <div className={styles.text}>
                    {pinnedMessageTitle && <h3 className={styles.title}>{pinnedMessageTitle}</h3>}
                    {pinnedMessageMarkdown && (
                        <MarkdownRenderer
                            textAlign="left"
                            base64MarkdownContent={pinnedMessageMarkdown}
                            hasDefaultStyling={false}
                        />
                    )}
                </div>
                {pinnedMessageImagePath && (
                    <div
                        className={styles.image}
                        style={{ backgroundImage: `url(${pathToURL(pinnedMessageImagePath)})` }}
                    ></div>
                )}
            </div>
        </div>
    );
}
