import classNames from 'classnames';
import * as styles from './styles.module.scss';

interface ButtonLinkProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    children: string;
    onClick: () => void;
    isHighlighted?: boolean;
    className?: string;
}

// Button which looks like an href
export function ButtonLink({ children, onClick, className, isHighlighted, ...props }: ButtonLinkProps) {
    return (
        <button
            className={classNames(styles.ButtonLink, className, { [styles.highlighted]: isHighlighted })}
            {...props}
            onClick={onClick}
        >
            {children}
        </button>
    );
}
