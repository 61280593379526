import { useState, useEffect, useMemo } from 'react';
import classnames from 'classnames';

import { MessageReactionSum, ReactedBy } from '@/models/feed';
import { useAppSelector } from '@/state/store';
import Icon from '@/components/Icon';

import * as styles from './styles.module.scss';

interface ReactionsProps {
    reactions?: MessageReactionSum[];
    onSubmitReaction?: (reaction: string) => void;
    controlsClassName?: string;
    existingReactionsClassName?: string;
    disabled?: boolean;
    currentUserId: string;
}
// To support reactions on older messages
const LEGACY_REACTIONS = {
    like: {
        type: 'like',
        imageUrl: '/assets/global/images/reactions/legacy/like.webp',
        title: 'smiling',
    },
    thumbsup: {
        type: 'thumbsup',
        imageUrl: '/assets/global/images/reactions/legacy/thumbsup.webp',
        title: 'thumbs up',
    },
    joy: {
        type: 'joy',
        imageUrl: '/assets/global/images/reactions/legacy/joy.webp',
        title: 'joy',
    },
    fire: {
        type: 'fire',
        imageUrl: '/assets/global/images/reactions/legacy/fire.webp',
        title: 'fire',
    },
    sob: {
        type: 'sob',
        imageUrl: '/assets/global/images/reactions/legacy/sob.webp',
        title: 'sob',
    },
    scream: {
        type: 'scream',
        imageUrl: '/assets/global/images/reactions/legacy/scream.webp',
        title: 'scream',
    },
};

function getReactedByAsString(reactedBy: ReactedBy[], currentUserID: string): string {
    if (reactedBy?.length) {
        const moveCurrentUserToStart = () => {
            const reorderedReactedBy = [...reactedBy];
            const index = reorderedReactedBy.findIndex((user) => user.userID === currentUserID);
            if (index !== -1) {
                const [currentUser] = reorderedReactedBy.splice(index, 1);
                const renamedUser = {
                    ...currentUser,
                    displayName: 'You',
                };
                return [renamedUser, ...reorderedReactedBy];
            }
            return reorderedReactedBy;
        };
        const reactedByWithCurrentUserAtStart = moveCurrentUserToStart();
        const names = reactedByWithCurrentUserAtStart.map((user) => user.displayName);

        if (names.length === 1) {
            return `${names[0]} reacted`;
        }
        if (names.length === 2) {
            return `${names[0]} and ${names[1]} reacted`;
        }
        if (names.length === 3) {
            return `${names[0]}, ${names[1]} and ${names[2]} reacted`;
        }
        if (names.length === 4) {
            return `${names[0]}, ${names[1]}, ${names[2]} and ${names[3]} reacted`;
        }
        if (names.length > 4) {
            return `${names[0]}, ${names[1]}, ${names[2]} and ${names.length - 3} more reacted`;
        }
    }
    return '';
}

export default function Reactions(props: ReactionsProps) {
    const { reactionControls, existingReactions } = ReactionsSeparateParts(props);
    return (
        <>
            {reactionControls}
            {existingReactions}
        </>
    );
}

// Returns controls and exisiting reactions as separate markup inside an object
// To allow greater flexibility if needed
export function ReactionsSeparateParts({
    reactions,
    onSubmitReaction,
    controlsClassName,
    disabled,
    currentUserId,
    existingReactionsClassName,
}: ReactionsProps) {
    const feed = useAppSelector((state) => state.feed);
    const [reactionMenuIsOpen, setReactionMenuIsOpen] = useState(false);

    const reactionsWithLegacy = { ...LEGACY_REACTIONS, ...feed.availableReactions };

    const orderedReactions = useMemo(() => {
        const result = [];
        if (!reactions) return result;
        for (const reactionKey in reactionsWithLegacy) {
            const matchingReaction = reactions?.find((reaction) => reaction.type === reactionKey);
            if (matchingReaction) {
                result.push(matchingReaction);
            }
        }
        return result;
    }, [reactions]);

    const userReactions = useMemo(() => {
        return (
            orderedReactions.filter((reaction) => reaction.reactedBy?.some((user) => user.userID === currentUserId)) ||
            []
        );
    }, [orderedReactions]);

    useEffect(() => {
        const closeReactionMenu = () => {
            setReactionMenuIsOpen(false);
        };

        if (reactionMenuIsOpen) {
            document.addEventListener('mouseup', closeReactionMenu);
            document.addEventListener('touchend', closeReactionMenu);
        }
        return () => {
            document.removeEventListener('mouseup', closeReactionMenu);
            document.removeEventListener('touchend', closeReactionMenu);
        };
    }, [reactionMenuIsOpen]);

    if (!Object.keys(reactionsWithLegacy).length) return null;

    const numberOfReactions = Object.keys(feed.availableReactions).length;
    const getReactionMenuWidth = () => {
        // Values must match CSS
        const reactionButtonWidth = 26;
        const reactionMenuMargin = 12;
        return numberOfReactions * reactionButtonWidth + reactionMenuMargin;
    };

    const reactionControls = (
        <>
            {!!numberOfReactions && (
                <div inert={disabled ? '' : undefined} style={{ pointerEvents: disabled ? 'none' : 'auto' }}>
                    <>
                        <button
                            className={classnames(styles.showButton, controlsClassName, {
                                [styles.isOpen]: reactionMenuIsOpen,
                            })}
                            onClick={() => setReactionMenuIsOpen(!reactionMenuIsOpen)}
                            style={{ display: disabled ? 'none' : 'inline-block' }}
                            disabled={disabled}
                        >
                            <Icon type="emoji" size={15} /> <span>+</span>
                        </button>
                        <div className={classnames(styles.reactionMenuContainer, controlsClassName)}>
                            <div
                                className={classnames(styles.reactionMenu, {
                                    [styles.show]: reactionMenuIsOpen,
                                })}
                                style={{ maxWidth: reactionMenuIsOpen ? getReactionMenuWidth() : 0 }}
                            >
                                {Object.keys(feed.availableReactions).map((reactionType) => {
                                    const { imageUrl, title } = feed.availableReactions[reactionType];
                                    return (
                                        <button
                                            disabled={disabled}
                                            tabIndex={reactionMenuIsOpen ? 0 : -1}
                                            key={reactionType}
                                            className={classnames(styles.reactionMenuButton, {
                                                [styles.active]: userReactions.some(
                                                    (userReaction) => userReaction.type === reactionType,
                                                ),
                                            })}
                                            onClick={() => {
                                                setReactionMenuIsOpen(false);
                                                onSubmitReaction?.(reactionType);
                                            }}
                                        >
                                            <span>
                                                <img src={imageUrl} alt={title}></img>
                                            </span>
                                        </button>
                                    );
                                })}
                            </div>
                        </div>
                    </>
                </div>
            )}
        </>
    );

    const existingReactions = (
        <>
            {!!orderedReactions?.length && (
                <div
                    className={classnames(styles.existingReactions, existingReactionsClassName)}
                    inert={disabled ? '' : undefined}
                    style={{ pointerEvents: disabled ? 'none' : 'auto' }}
                >
                    {orderedReactions?.map((reaction: MessageReactionSum) => {
                        const { imageUrl, title } = reactionsWithLegacy[reaction.type];

                        if (reaction.count <= 0) return null;

                        return (
                            <button
                                title={getReactedByAsString(reaction.reactedBy, currentUserId)}
                                disabled={disabled}
                                key={reaction.type}
                                className={classnames(styles.exisitingReaction, {
                                    [styles.active]: userReactions.some(
                                        (userReaction) => userReaction.type === reaction.type,
                                    ),
                                })}
                                onClick={() => onSubmitReaction?.(reaction.type)}
                            >
                                {reaction.count} <img src={imageUrl} alt={title}></img>
                            </button>
                        );
                    })}
                </div>
            )}
        </>
    );

    return {
        reactionControls,
        existingReactions,
    };
}
