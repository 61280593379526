import { useEffect, useRef, useState, TransitionEvent } from 'react';
import classNames from 'classnames';

import { useAppDispatch, useAppSelector } from '@/state/store';
import { closeProfile, setIsShopOpen } from '@/state/features/app';
import { getArisewareScoresForUser, getTitles } from '@/arise/api';
import { useAudio } from '@/hooks/audio';
import { useTheme } from '@/theme';
import { Title } from '@/models/profile';
import IconButton from '@/components/IconButton';
import LoadingScreen from '@/components/LoadingScreen';
import Badge from '@/components/Badge';
import ThemedMenuBar from '@/components/ThemedMenuBar';
import DisplayNameWithBadges from '@/components/DisplayNameWithBadges';
import AvatarLayered from '@/components/AvatarLayered';
import Rank from '@/components/Rank';
import { processTitles } from '@/utils/profile';
import { sendEvent } from '@/utils/analytics';
import { ArisewareScore } from '@/models/misc';

import ProfileCharacter from './ProfileCharacter';
import ProfileBackground from './ProfileBackground';
import ProfileStatsSummary, { ProfileStatSummaryItem } from './ProfileStatsSummary';
import ProfileEditor from './ProfileEditor';
import ArisewareScores from './ArisewareScores';

import * as styles from './styles.module.scss';

export default function ProfileDrawer() {
    const dispatch = useAppDispatch();
    const closeButtonRef = useRef<HTMLButtonElement>(null);
    const { audio } = useAudio();
    const theme = useTheme();
    const { isProfileOpen, isShopOpen, currentlyViewingProfile: profile } = useAppSelector((state) => state.app);
    const currentUserProfile = useAppSelector((state) => state.profile.currentUserProfile);
    const isViewingOwnProfile = profile?.userID === currentUserProfile?.userID;

    const [previousUserID, setPreviousUserID] = useState(null);
    const [arisewareScores, setArisewareScores] = useState<ArisewareScore[]>(null);
    const [titles, setTitles] = useState(null);
    const [characterIsLoaded, setCharacterIsLoaded] = useState(false);
    const [backgroundIsLoaded, setBackgroundIsLoaded] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const isLoading = !(
        arisewareScores !== null &&
        titles !== null &&
        previousUserID === profile?.userID &&
        characterIsLoaded &&
        backgroundIsLoaded
    );

    useEffect(() => {
        if (isProfileOpen && profile?.userID) {
            let areRequestsStale = false; // To stop stale request issues
            if (previousUserID !== profile.userID) {
                // If is a different user, clear the old data
                setArisewareScores(null);
                setTitles(null);
            }
            getArisewareScoresForUser(profile.userID)
                .then((result) => {
                    if (!areRequestsStale) {
                        setArisewareScores(result);
                    }
                })
                .catch(() => {
                    if (!areRequestsStale) {
                        setArisewareScores([]);
                    }
                });

            getTitles(profile.userID)
                .then((result) => {
                    if (!areRequestsStale) {
                        const processedTitles = processTitles(result);
                        setTitles(processedTitles);
                    }
                })
                .catch(() => {
                    if (!areRequestsStale) {
                        setTitles([]);
                    }
                });
            setPreviousUserID(profile.userID);
            return () => {
                // Drawer closed, or ID changed, so set all requests as stale
                areRequestsStale = true;
            };
        }
    }, [isProfileOpen, profile?.userID, profile?.title?.title]);

    useEffect(() => {
        if (isProfileOpen) {
            audio?.play('gust-open');
            sendEvent('open_profile', profile?.id);
        }
        setIsEditing(false);
    }, [isProfileOpen]);

    // Move focus to drawer when opened
    function onTransitionEnd(e: TransitionEvent<HTMLDivElement>) {
        if (isProfileOpen && e.target === e.currentTarget) {
            setTimeout(() => {
                closeButtonRef.current?.focus();
            }, 200);
        }
    }

    return (
        <div
            className={classNames(styles.ProfileDrawer, {
                [styles['isOpen']]: isProfileOpen,
                [styles.isLoaded]: !isLoading,
            })}
            inert={!isProfileOpen ? '' : undefined}
            onTransitionEnd={onTransitionEnd}
        >
            <div className={styles.topBar}>
                <IconButton
                    buttonRef={closeButtonRef}
                    iconType="close"
                    buttonStyle="round"
                    ariaLabel="Close Profile"
                    className={styles.closeButton}
                    onClick={() => {
                        audio?.play('gust-close');
                        sendEvent('close_profile', profile?.id);
                        dispatch(closeProfile());
                    }}
                />
                {(theme.metadata?.hasProfileShop || theme.metadata?.hasExternalShop) && (
                    <IconButton
                        buttonStyle="round"
                        iconType="shop"
                        title="Open Shop"
                        ariaLabel="Open Shop"
                        onClick={() => {
                            dispatch(setIsShopOpen(true));
                            sendEvent('open_shop_from_profile_header');
                        }}
                        className={styles.shopButton}
                    />
                )}
            </div>
            <div
                className={classNames(styles.content, { [styles.isEditing]: isEditing })}
                inert={isLoading ? '' : undefined}
            >
                {profile && (
                    <>
                        <div className={styles.profileCharacterContainer}>
                            <ProfileBackground
                                classname={styles.profileBackground}
                                onLoad={() => setBackgroundIsLoaded(true)}
                                rivUrl={profile.background?.rivFileURL}
                                imagePath={profile.background?.imagePath}
                            />
                            <ProfileCharacter
                                classname={styles.profileCharacter}
                                onLoad={() => setCharacterIsLoaded(true)}
                                rivUrl={profile.character?.rivFileURL}
                                imagePath={profile.character?.imagePath}
                                hexColour={profile.background?.colour}
                            />
                            <Rank
                                key={profile.userID}
                                classname={styles.factionImage}
                                faction={profile.faction}
                                rank={profile.supplyRunRank}
                            />
                            {isViewingOwnProfile && (
                                <IconButton
                                    iconType={isEditing ? 'priority' : 'edit'}
                                    buttonStyle="round"
                                    ariaLabel={isEditing ? 'Stop Editing profile' : 'Edit Profile'}
                                    className={styles.editButton}
                                    onClick={() => {
                                        setIsEditing(!isEditing);
                                    }}
                                />
                            )}
                            <AvatarLayered
                                classname={styles.editingAvatar}
                                backgroundImagePath={profile.background?.thumbnailPath}
                                characterImagePath={profile.character?.thumbnailPath}
                                frameImagePath={profile.avatarFrame?.imagePath}
                                title={`${profile.displayName}'s avatar`}
                            />
                        </div>
                        <div
                            className={classNames(styles.bottomContent, { [styles.isEditing]: isEditing })}
                            inert={!isEditing ? '' : undefined}
                        >
                            {profile && (
                                <ThemedMenuBar classname={styles.themedBar}>
                                    <div className={styles.themedBarContent}>
                                        <AvatarLayered
                                            classname={styles.avatar}
                                            backgroundImagePath={profile.background?.thumbnailPath}
                                            characterImagePath={profile.character?.thumbnailPath}
                                            frameImagePath={profile.avatarFrame?.imagePath}
                                            title={`${profile.displayName}'s avatar`}
                                        />
                                        <DisplayNameWithBadges profile={profile} hasFaction={false} />
                                    </div>
                                </ThemedMenuBar>
                            )}
                            <ProfileStatsSummary
                                key={profile.userID}
                                numberOfTitles={titles?.length}
                                puzzlesCompleted={arisewareScores?.filter((score) => score.isCompleted)?.length}
                                userID={profile.userID}
                                role={profile.role}
                            />
                            <ArisewareScores arisewareScores={arisewareScores} />
                            {!!titles?.length && (
                                <div className={styles.titlesUnlocked}>
                                    <ProfileStatSummaryItem
                                        classname={styles.titleStat}
                                        iconType="crown"
                                        title="Titles:"
                                        value={titles?.length}
                                    />
                                    <ul>
                                        {titles?.map((title: Title, index: number) => (
                                            <li key={index}>
                                                <Badge
                                                    text={title.title}
                                                    rarity={title.rarity}
                                                    title={title.rarity?.toString()}
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </>
                )}
                {isEditing && <ProfileEditor />}
                <LoadingScreen show={isLoading} classname={styles.loadingScreen} />
            </div>
        </div>
    );
}
