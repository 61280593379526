@import '../../../../style/globals.scss';

.AuthButton {
    display: block;
    width: 100%;
    border-radius: 20px;
    color: var(--token-system-text-default);
    background-color: var(--token-system-brand);
    height: 40px;
    text-align: center;
    font-weight: 500;
    font-size: 14px;

    &:hover {
        filter: brightness(1.5);
    }

    &:disabled {
        filter: brightness(0.5);
    }
}

.externalLoginButtons {
    width: 100%;

    & > *:not(:last-child) {
        margin-bottom: 16px;
    }
}

// Taken from https://developers.google.com/identity/branding-guidelines
.gsi-material-button {
    font-weight: bold;
    user-select: none;
    appearance: none;
    background-color: white;
    background-image: none;
    border: 1px solid #747775;
    border-radius: 20px;
    box-sizing: border-box;
    color: #1f1f1f;
    cursor: pointer;
    font-size: 14px;
    height: 40px;
    outline: none;
    overflow: hidden;
    padding: 0 12px;
    position: relative;
    text-align: center;
    transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
    vertical-align: middle;
    white-space: nowrap;
    width: 100%;
    max-width: 400px;
    min-width: min-content;

    & .gsi-material-button-icon {
        height: 20px;
        margin-left: 30px;
        margin-right: -30px;
        min-width: 20px;
        width: 20px;
    }

    & .gsi-material-button-content-wrapper {
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        height: 100%;
        justify-content: space-between;
        position: relative;
        width: 100%;
    }

    & .gsi-material-button-contents {
        flex-grow: 1;
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: top;
    }

    & .gsi-material-button-state {
        transition: opacity 0.218s;
        bottom: 0;
        left: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
    }

    &:active .gsi-material-button-state,
    &:focus .gsi-material-button-state {
        background-color: #303030;
        opacity: 12%;
    }

    &:hover {
        box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);

        & .gsi-material-button-state {
            background-color: #303030;
            opacity: 8%;
        }
    }
}
